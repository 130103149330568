<template>
  <v-container class="fill-height">
    <v-row class="fill-height" justify="center" align="stretch">
      <v-col cols="12" md="10">
        <v-card
          class="w-100 h-100 d-flex flex-column justify-center align-center"
          color="surface"
          flat
        >
          <v-container>
            <v-row justify="center">
              <v-col cols="6" md="5">
                <v-responsive
                  :aspect-ratio="1"
                  style="max-height: 100%; max-width: 100%"
                  class="text-center"
                >
                  <img src="~/assets/approved.svg" style="max-height: 100%; max-width: 100%" />
                </v-responsive>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="7" class="text-center">
                <span class="text-subtitle-2">{{
                  $t('summary.approved', { brand, evidence })
                }}</span>
              </v-col>
            </v-row>
            <v-row v-if="smAndDown" justify="center">
              <v-col cols="12" md="7" class="text-center">
                <v-btn color="secondary" @click="onBack">
                  <v-icon class="me-2">mdi-chevron-left</v-icon>
                  <span>{{ $t('actions.back') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useDisplay } from 'vuetify'
export default defineComponent({
  name: 'PartialsApprovedEvidence',
  props: {
    evidenceKey: {
      type: String,
      default: 'unknown',
    },
  },
  setup(props) {
    const display = useDisplay()
    const runtimeConfig = useRuntimeConfig()
    const xs = computed(() => display.xs.value)
    const smAndDown = computed(() => display.smAndDown.value)
    const brand = computed(() => runtimeConfig.public.brand)
    const router = useRouter()
    const localePath = useLocalePath()
    const { t } = useI18n({ useScope: 'global' })
    const evidence = computed(() => t(`evidences.${props.evidenceKey}.title`).toLowerCase())
    const onBack = () => {
      router.push(localePath({ name: 'index' }))
    }
    return {
      smAndDown,
      brand,
      xs,
      onBack,
      evidence,
    }
  },
})
</script>
